<template>
  <div class="Connect">
    <!-- <div class="title">联系我们</div>
		<div class="main">
			<div class="form">
				<el-form ref="form" :model="form">
					<el-row gutter="10">
						<el-col :span="`${$store.state.platform == 'pc'?4:8}`">
							<el-form-item label="称谓">
								<el-select>
									<el-option label="先生"></el-option>
									<el-option label="女士"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="`${$store.state.platform == 'pc'?10:8}`">
							<el-form-item label="姓氏"><el-input v-model="form.name"></el-input></el-form-item>
						</el-col>
						<el-col :span="`${$store.state.platform == 'pc'?10:8}`">
							<el-form-item label="名字"><el-input v-model="form.name"></el-input></el-form-item>
						</el-col>
					</el-row>
					<el-row gutter="10">
						<el-col :span="8">
							<el-form-item label="生日"><el-select style="width: 100%;"></el-select></el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="月"><el-select style="width: 100%;"></el-select></el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="日"><el-select style="width: 100%;"></el-select></el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="submit">保存</div>
			</div>
		</div> -->
    <!-- 账户信息 -->
    <div class="title">{{ $t("userTab.setting") }}</div>
    <div class="form">
      <div class="accountInfo">
        <p>{{ $t("tip.dqyh") }}</p>
        <p>{{ detail.email }}</p>
      </div>
      <div class="submits">
        <!-- <div class="submit">修改手机号</div> -->
        <div class="submit" @click="show.changeCode = true">
          {{ $t("password.confrim") }}
        </div>
      </div>
    </div>
    <!-- 修改手机号 -->
    <!-- 	<div class="title">修改手机号</div>
		<div class="form">
			<div class="accountInfo">
				<p>原手机号</p>
				<p>152****0000</p>
			</div>
			<el-form ref="form" :model="form">
				<el-row >
					<el-col :span="$store.state.platform == 'pc'?7:12">
						<el-form-item label="图形验证码" style="margin-right: 10px;"><el-input v-model="form.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="$store.state.platform == 'pc'?3:7">
						<el-form-item class="empty" label="empty" >
							<button class="yzCode"></button>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="$store.state.platform == 'pc'?7:12">
						<el-form-item label="手机验证码"><el-input v-model="form.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="$store.state.platform == 'pc'?3:7">
						<el-form-item class="empty" label="手机验证码">
							<button class="sendCode">发送验证码</button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="submit">保存</div>
			<div class="cancel">取消</div>
		</div> -->
    <!-- 修改密码 -->
    <template v-if="show.changeCode">
      <div class="title">{{ $t("password.confrim") }}</div>
      <div class="form">
        <el-form ref="form" :model="form">
          <el-row>
            <el-col :span="$store.state.platform == 'pc' ? 9 : 19">
              <el-form-item :label="$t('setting.password')">
                <el-input
                  :placeholder="$t('setting.passwordPlacrholder')"
                  type="password"
                  show-password
                  v-model="form.password"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="$store.state.platform == 'pc' ? 9 : 19">
              <el-form-item :label="$t('setting.newPassword')">
                <el-input
                  :placeholder="$t('setting.passwordNewPlacrholder')"
                  type="password"
                  show-password
                  v-model="form.newPassword"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="$store.state.platform == 'pc' ? 9 : 19">
              <el-form-item :label="$t('rigister.con_password')">
                <el-input
                  :placeholder="$t('rigister.repasswordTip')"
                  type="password"
                  show-password
                  v-model="form.again"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="submit" @click="submit" style="width:100px">{{ $t("address.save") }}</div>
        <div class="cancel" @click="show.changeCode = false">
          {{ $t("address.cancel") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api from "../../api/request.js";
export default {
  name: "infomation",
  components: {},
  data() {
    return {
      detail: {},
      form: {
        password: "",
        newPassword: "",
        again: "",
      },
      show: {
        changeCode: false,
      },
    };
  },
  watch: {
    "show.changeCode": function (res) {
      if (!res) {
        this.form = {
          password: "",
          newPassword: "",
          again: "",
        };
      }
    },
  },
  created() {
    this.detail = this.$storage.get("user_info");
  },
  methods: {
    submit() {
      this.$dialog.loading();
      Api.Login.changePwd(this.form).then((res) => {
        if (res.data.status == "SUCCESSS") {
          this.$dialog.notify(this.$t("setting.success"), this.$t("setting.success"));
        }
        // show.changeCode = false
        this.$dialog.close();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 65px;
}
.tip {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.sendCode {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background: #ffffff;
  border: 1px solid #757575;
  border-left: none;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
}
.yzCode {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  height: 40px;
  background: #ccc;
}
.accountInfo {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-top: 20px;
  & > p {
    margin-bottom: 20px;
  }
}
.form {
  padding: 20px 40px 40px;
  border: 1px solid #d9d9d9;
  position: relative;
  .cancel {
    height: 48px;
    line-height: 48px;
    position: absolute;
    left: 280px;
    bottom: 40px;
    font-size: 14px;
    font-weight: 600;
    color: #123178;
    cursor: pointer;
  }
  .el-form {
    width: 900px;
    .el-row {
      margin-top: 20px;
    }
    .el-form-item {
      margin-bottom: 0;
      &.empty {
        /deep/.el-form-item__label {
          opacity: 0;
        }
      }
    }
    /deep/.el-form-item__label {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      margin: 0 0 12px;
    }
    /deep/.el-input__inner {
      border-radius: 0;
      border-color: #757575;
    }
    /deep/.el-textarea__inner {
      border-radius: 0;
      border-color: #757575;
      height: 208px;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #000;
      font-size: 12px;
      font-weight: bolder;
    }
  }
}
.submit {
  width: 200px;
  height: 48px;
  background: #123178;
  border-radius: 6px;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    background-color: #021450;
  }
}
.submits {
  display: flex;
  .submit {
    margin-top: 0;
    background: #fff;
    border: 1px solid #123178;
    color: #123178;
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 4vw;
    line-height: 12vw;
  }
  .form {
    padding: 3vw;
    border: 1px solid #d9d9d9;
    position: relative;
    .cancel {
      height: 9vw;
      line-height: 9.5vw;
      position: absolute;
      left: 45vw;
      bottom: 3vw;
      font-size: 3.5vw;
      font-weight: 600;
      color: #123178;
      cursor: pointer;
    }
    .el-form {
      width: 100%;
      .el-row {
        margin-top: 3vw;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      /deep/.el-form-item__label {
        font-size: 3.5vw;
        font-weight: 400;
        color: #000000;
        margin: 0 0 2vw;
      }
      /deep/.el-input__inner {
        border-radius: 0;
        border-color: #757575;
        height: 9vw;
      }
      /deep/.el-textarea__inner {
        border-radius: 0;
        border-color: #757575;
        height: 208px;
      }
      /deep/.el-select .el-input .el-select__caret {
        color: #000;
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }
  .submit {
    height: 9vw;
    background: #123178;
    border-radius: 6px;
    text-align: center;
    line-height: 9.5vw;
    font-size: 3.5vw;
    font-weight: 600;
    color: #ffffff;
    margin-top: 6vw;
    cursor: pointer;
  }
  .submits {
    display: flex;
    .submit {
      margin-top: 0;
      background: #fff;
      border: 1px solid #123178;
      color: #123178;
      margin-right: 6vw;
    }
  }
  .yzCode {
    height: 9vw;
    background: #ccc;
  }
  .sendCode {
    height: 9vw;
  }
}
</style>
